
import { Vue } from 'vue-class-component';
import router from '../router';

class FourOhFour extends Vue {
    backHome() {
        router.push({
            name: 'login'
        });
    }
}

export default FourOhFour;
